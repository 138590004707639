import { TemplateVariableTypeEnum } from '../../entities/message-template';
import { HandlebarHelpersEnum } from './handlebarHelpers';
export function getTemplateVariables(bod) {
    const stringVariables = bod
        .filter((body) => body.type === 'MustacheStatement')
        .flatMap((body) => {
        var _a, _b, _c;
        const varName = ((_a = body.params[0]) === null || _a === void 0 ? void 0 : _a.original) || body.path.original;
        if (!shouldAddVariable(varName)) {
            return [];
        }
        if ((_b = body.params[0]) === null || _b === void 0 ? void 0 : _b.original) {
            if (!Object.values(HandlebarHelpersEnum).includes(body.path.original)) {
                return [];
            }
        }
        return {
            type: TemplateVariableTypeEnum.STRING,
            name: ((_c = body.params[0]) === null || _c === void 0 ? void 0 : _c.original) || body.path.original,
            defaultValue: '',
            required: false,
        };
    });
    const arrayVariables = bod
        .filter((body) => body.type === 'BlockStatement' && ['each', 'with'].includes(body.path.original))
        .flatMap((body) => {
        var _a;
        const varName = ((_a = body.params[0]) === null || _a === void 0 ? void 0 : _a.original) || body.path.original;
        if (!shouldAddVariable(varName)) {
            return [];
        }
        const nestedVariablesInBlock = getTemplateVariables(body.program.body).map((mustVar) => {
            return Object.assign(Object.assign({}, mustVar), { name: `${varName}.${mustVar.name}` });
        });
        if (['with'].includes(body.path.original)) {
            return [...nestedVariablesInBlock];
        }
        return [
            {
                type: TemplateVariableTypeEnum.ARRAY,
                name: varName,
                required: false,
            },
            ...nestedVariablesInBlock,
        ];
    });
    const boolVariables = bod
        .filter((body) => body.type === 'BlockStatement' && ['if', 'unless'].includes(body.path.original))
        .flatMap((body) => {
        var _a;
        const varName = ((_a = body.params[0]) === null || _a === void 0 ? void 0 : _a.original) || body.path.original;
        if (!shouldAddVariable(varName)) {
            return [];
        }
        if (body.params.length > 1) {
            return [];
        }
        const nestedVariablesInBlock = getTemplateVariables(body.program.body);
        return [
            {
                type: TemplateVariableTypeEnum.BOOLEAN,
                name: varName,
                defaultValue: true,
                required: false,
            },
            ...nestedVariablesInBlock,
        ];
    });
    return stringVariables.concat(arrayVariables).concat(boolVariables);
}
const shouldAddVariable = (variableName) => {
    const validRegExp = /^[a-zA-Z_][a-zA-Z0-9_-]*?/;
    const isValid = variableName.match(validRegExp);
    return isValid;
};
