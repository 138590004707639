import { ChannelTypeEnum } from '../../../entities/message-template';
import { apnsConfig, fcmConfig, expoConfig } from '../credentials';
import { PushProviderIdEnum } from '../provider.enum';
export const pushProviders = [
    {
        id: PushProviderIdEnum.FCM,
        displayName: 'Firebase Cloud Messaging',
        channel: ChannelTypeEnum.PUSH,
        credentials: fcmConfig,
        docReference: 'https://docs.novu.co/channels/push/fcm',
        logoFileName: { light: 'fcm.svg', dark: 'fcm.svg' },
    },
    {
        id: PushProviderIdEnum.EXPO,
        displayName: 'Expo Push',
        channel: ChannelTypeEnum.PUSH,
        credentials: expoConfig,
        docReference: 'https://docs.expo.dev/push-notifications/overview/',
        logoFileName: { light: 'expo.svg', dark: 'expo.svg' },
    },
    {
        id: PushProviderIdEnum.APNS,
        displayName: 'APNs',
        channel: ChannelTypeEnum.PUSH,
        credentials: apnsConfig,
        docReference: 'https://docs.novu.co/channels/push/apns',
        logoFileName: { light: 'apns.png', dark: 'apns.png' },
        betaVersion: true,
    },
];
