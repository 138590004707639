var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient, } from '@novu/shared';
export class ApiService {
    constructor(backendUrl) {
        this.backendUrl = backendUrl;
        this.isAuthenticated = false;
        this.httpClient = new HttpClient(backendUrl);
    }
    setAuthorizationToken(token) {
        this.httpClient.setAuthorizationToken(token);
        this.isAuthenticated = true;
    }
    disposeAuthorizationToken() {
        this.httpClient.disposeAuthorizationToken();
        this.isAuthenticated = false;
    }
    updateAction(messageId, executedType, status, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post(`/widgets/messages/${messageId}/actions/${executedType}`, {
                executedType,
                status,
                payload,
            });
        });
    }
    markMessageAsSeen(messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const messageIdString = messageId ? messageId.toString() : '';
            return yield this.httpClient.post(`/widgets/messages/${messageIdString}/seen`, {});
        });
    }
    markMessageAsRead(messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            const messageIdString = messageId ? messageId.toString() : '';
            return yield this.httpClient.post(`/widgets/messages/${messageIdString}/read`, {});
        });
    }
    markMessageAs(messageId, mark) {
        return __awaiter(this, void 0, void 0, function* () {
            const markPayload = mark.seen === undefined && mark.read === undefined
                ? { seen: true }
                : mark;
            return yield this.httpClient.post(`/widgets/messages/markAs`, {
                messageId,
                mark: markPayload,
            });
        });
    }
    getNotificationsList(page, query = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.get(`/widgets/notifications/feed`, Object.assign({ page }, query));
        });
    }
    initializeSession(appId, subscriberId, hmacHash = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post(`/widgets/session/initialize`, {
                applicationIdentifier: appId,
                subscriberId: subscriberId,
                hmacHash,
            });
        });
    }
    postUsageLog(name, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post('/widgets/usage/log', {
                name: `[Widget] - ${name}`,
                payload,
            });
        });
    }
    getUnseenCount(query = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.get('/widgets/notifications/unseen', query);
        });
    }
    getTabCount(query = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.get('/widgets/notifications/count', query);
        });
    }
    getOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get('/widgets/organization');
        });
    }
    getUserPreference() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get('/widgets/preferences');
        });
    }
    updateSubscriberPreference(templateId, channelType, enabled) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.patch(`/widgets/preferences/${templateId}`, {
                channel: { type: channelType, enabled },
            });
        });
    }
}
